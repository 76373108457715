import { useCallback, useEffect, useRef, useState } from "react";
import classes from "./Birthday.module.css";
import { loadFull } from "tsparticles";
import Particles from "react-particles";
import AddWish from "../../components/Birthday/AddWish";
import { FIREBASE_URL } from "../../utils/Const";
import { Helmet } from "react-helmet";

const countDownDate = new Date("Jun 05, 2023 00:00:00").getTime();
// https://particles.js.org/docs/classes/Options_Classes_Particles_Shape_Shape.Shape.html#image
const BG_IMG_LIST = [
  `url('https://i.imgur.com/AsHdyNC.jpg')`,
  `url('https://i.imgur.com/Sp4CFFt.jpg')`,
  `url('https://i.imgur.com/7R9RzbH.jpg')`,
  `url('https://i.imgur.com/j7EUtNB.jpg')`,
  `url('https://i.imgur.com/vPeNQKm.jpg')`,
  `url('https://i.imgur.com/NB60kkg.jpg')`,
  `url('https://i.imgur.com/gHSFZk6.jpg')`,
  `url('https://i.imgur.com/LSgFgxw.jpg')`,
  `url('https://i.imgur.com/152gtFc.jpg')`,
  `url('https://i.imgur.com/2DJsNO5.jpg')`,
  `url('https://i.imgur.com/OPH9RGL.jpg')`,
  `url('https://i.imgur.com/6GKdOHI.jpg')`,
  `url('https://i.imgur.com/q6dvZSC.jpg')`,
  `url('https://i.imgur.com/q6AelRV.jpg')`,
  `url('https://i.imgur.com/aGTGlde.jpg')`,
];

const Birthday = () => {
  const [wishes, setWishes] = useState([
  ]);
  const [countDown, setCountDown] = useState("");

  const [bgImg, setBGImg] = useState(BG_IMG_LIST[0]);
  const [isBd, setIsBd] = useState(false);
  const [showYT, setShowYT] = useState(false);
  const iframeRef = useRef(null);
  const particlesInit = useCallback(async (engine) => {
    // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {}, []);

  const [cd, setCd] = useState("");
  const [ch, setCh] = useState("");

  const [cm, setCm] = useState("");

  const countDownTimer = () => {
    let x = setInterval(() => {
      // Get today's date and time
      let now = new Date().getTime();

      // Find the distance between now and the count down date
      let distance = countDownDate - now;

      // Time calculations for days, hours, minutes and seconds
      const days = Math.floor(distance / (1000 * 60 * 60 * 24));
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((distance % (1000 * 60)) / 1000);
      setCountDown(
        days + "d " + hours + "h " + minutes + "m " + seconds + "s "
      );
      setCd(String(days).padStart(2, "0"));
      setCh(String(hours).padStart(2, "0"));
      setCm(String(minutes).padStart(2, "0"));

      // If the count down is finished, write some text
      if (distance < 0) {
        clearInterval(x);
        setIsBd(true);
      }
    }, 1000);
  };

  const clock = () => {
    return (
      <div className={classes.countDownContainer}>
        <h4>Happy Birthday Party</h4>
        <div>
          <div className={classes.countdownSquare}>
            <h2>{cd?.charAt(0)}</h2>
          </div>
          <h3>day</h3>
        </div>
        <div className={classes.countdownSquare}>
          <h2>{cd?.charAt(1)}</h2>
        </div>

        <div className={classes.colon}>
          <h2>:</h2>
        </div>

        <div>
          <div className={classes.countdownSquare}>
            <h2>{ch?.charAt(0)}</h2>
          </div>
          <h3>hour</h3>
        </div>
        <div className={classes.countdownSquare}>
          <h2>{ch?.charAt(1)}</h2>
        </div>

        <div className={classes.colon}>
          <h2>:</h2>
        </div>

        <div>
          <div className={classes.countdownSquare}>
            <h2>{cm?.charAt(0)}</h2>
          </div>
          <h3>mins</h3>
        </div>
        <div className={classes.countdownSquare}>
          <h2>{cm?.charAt(1)}</h2>
        </div>
      </div>
    );
  };

  const requestWishes = async () => {
    const res = (
      await fetch(FIREBASE_URL + "bd.json", {
        method: "GET",
      })
    ).json();

    res.then((wishesRes) => {
      console.log(wishesRes);
      for (const key in wishesRes) {
        setWishes((prevState) => {
          return [...prevState, wishesRes[key]?.text];
        });
      }
    });
  };

  useEffect(() => {
    countDownTimer();
    requestWishes();
    setInterval(() => {
      const randomNumber = Math.floor(Math.random() * BG_IMG_LIST.length);
      setBGImg(BG_IMG_LIST[randomNumber]);
    }, 10000);

    setTimeout(() => {
      iframeRef.current?.click();
    }, 2000);

    setTimeout(() => {
      setShowYT(true);
      iframeRef.current?.click();
    }, 6000);

    iframeRef.current?.click();
  }, []);

  const isShowWhenBd = () => {
    if (isBd) {
      return <h2>{``}</h2>;
    }
  };

  const isShowWhenCountDown = () => {
    if (!isBd) {
      return <div>{clock()}</div>;
    }
  };

  const onAdd = (wish) => {
    setWishes((prevState) => {
      return [...prevState, wish];
    });
  };

  return (
    <div className={classes.container}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Birthday</title>
        <meta name="description" content="Hua Cat Luong React JS projects" />
      </Helmet>
      <h1>Happy Birthday!</h1>
      {isShowWhenBd()}
      {/*{isShowWhenCountDown()}*/}

      {showYT ? (
        <iframe
          className="video"
          ref={iframeRef}
          title="Youtube player"
          sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
          src={`https://youtube.com/embed/p6EjUkQ3Ut0?autoplay=1`}
          width="40"
          height="40"
          frameborder="0"
          style={{ position: "absolute", right: 10, bottom: 10 }}
          allow="autoplay"
        ></iframe>
      ) : null}

      {/*<AddWish onAdd={onAdd} />*/}
      <Particles
        id="tsparticles"
        init={particlesInit}
        loaded={particlesLoaded}
        options={{
          fullScreen: {
            enable: true,
            zIndex: -1,
          },
          name: "Background Mask",
          particles: {
            number: {
              value: wishes.length < 20 ? wishes.length : 20,
              density: {
                enable: true,
              },
            },
            color: {
              value: "#fff", // "#EF24B8",
            },
            shape: {
              type: ["image", "character", "char"],
              character: {
                value: wishes,
                font: "Arial",
                style: "",
                weight: "",
              },
              // "image": {
              //     "src": "https://i.imgur.com/99TrwWo.png",
              //     "width": 103 / 1.5,
              //     "height": 222 / 1.5,
              //     "replaceColor": false
              // }
            },
            opacity: {
              value: 0.9,
            },
            size: {
              value: {
                min: 5,
                max: 10,
              },
            },
            links: {
              enable: true,
              distance: 160,
              color: "#ac0e77",
              opacity: 1,
              width: 1,
            },
            move: {
              enable: true,
              speed: 1.5,
              direction: "none",
              random: false,
              straight: false,
            },
          },
          interactivity: {
            events: {
              onHover: {
                enable: true,
                mode: ["bubble"],
              },
              onClick: {
                enable: true,
                mode: "push",
              },
            },
            modes: {
              bubble: {
                distance: 350,
                size: 27,
                duration: 3,
                opacity: 0.8,
              },
              push: {
                quantity: 2,
              },
            },
          },
          backgroundMask: {
            enable: false,
            cover: {
              value: {
                r: 221,
                g: 180,
                b: 234,
              },
            },
          },
          background: {
            color: "#000",
            image: bgImg,
            position: "center",
            repeat: "repeat",
            size: "cover",
          },
        }}
      />
    </div>
  );
};

export default Birthday;
